@import '../../utils-old/global.scss';

.Feedback {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &-Wrapper {
    max-width: 808px;
    min-height: 260px;
    max-height: 100%;
    // overflow-y: auto;
    width: 100%;
    position: relative;
  }

  &-ErrorMessage {
    position: absolute;
    bottom: 10px;
    left: 50%;
    background: $ErrorColor;
    color: white;
    padding: 2%;
    transform: translateX(-50%);
    width: 100%;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -9px;
      margin-left: 2%;
      border-width: 10px 10px 0px 10px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $ErrorColor;
    }
  }

  &-UserInfo {
    padding: 24px;
    display: flex;

    .Avatar {
      margin-top: -64px;
      margin-right: 24px;
      
      .Avatar-Img {
        border: 1px solid $BorderColor;
      }
    }

    &-Data {

      h1 {
        margin: 0px;
      }
    }
  }

  &-AdditionalData {
    background-color: $GrayBackground;
    box-sizing: border-box;
    border: 1px solid $BorderColor;
    padding: 24px 24px 40px;
  }

  &-Form {
    position: relative;
    z-index: 2;
    background-color: $GrayBackground;
    box-sizing: border-box;
    border: 1px solid $BorderColor;

    .Input,
    .Textarea,
    .Checkbox,
    &-Actions {
      border-top: 1px solid $BorderColor;
    }

    &-Actions {
      position: relative;
      display: flex;
      justify-content: flex-end;
      padding: 24px;

      .Button {
        display: flex;
        padding: 10px 4% 11px;
      }

      > *:nth-last-child(n + 2) {
        margin-right: 24px;
      }
    }
  }

  &-Spinner {
  }
}