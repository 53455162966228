@import '../../../../utils-old/global.scss';


.LoginForm {

  &-ErrorMessage,
  &-SuccessMessage {
    position: absolute;
    bottom: 10px;
    left: 50%;
    color: white;
    padding: 2%;
    transform: translateX(-50%);
    width: 100%;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
  }

  &-ErrorMessage {
    background: $ErrorColor;

    &::after {
      content: '';
      display: block;
      position: absolute;
      bottom: -9px;
      margin-left: 2%;
      border-width: 10px 10px 0px 10px;
      border-style: solid;
      border-color: transparent;
      border-top-color: $ErrorColor;
    }
  }

  &-SuccessMessage {
    z-index: 51;
    bottom: auto;
    top: 0;
    background: $SuccessColor;
  }

  &-Form {
    position: relative;
    z-index: 1;
    background-color: $GrayBackground;
    box-sizing: border-box;
    border: 1px solid $BorderColor;
    // max-width: 600px;
    min-height: 260px;
    // margin: 0 auto;

    .Input,
    .Checkbox,
    .Select,
    .CheckboxInput,
    .LoginForm-Providers,
    .LoginForm-CaptchaImg {
      border-top: 1px solid $BorderColor;
    }

    &-Text {
      padding: 15px 15px;
      position: relative;
    }

    &-Actions {
      direction: rtl;
      padding: 0 15px 15px;

      .LoginForm-SmsCodeButton:disabled {
        background-color: transparent;
      }

      > *:nth-last-child(n + 2) {
        margin-left: 16px;
      }

      .Button {
        padding: 10px 4% 11px;
      }
    }

    .TickTimer {
      text-align: center;
    }
  }
  
  &-Providers {
    background-color: #fff;

    .TabsElement {
      margin: 0 24px -1px;

      &-TabBtn {
        padding: 8px 0;
      }
    }
    // display: flex;

    // .Button {
    //   flex: 1;

    //   &:nth-child(n + 2) {
    //     border-left: 1px solid $BorderColor;
    //   }
    // }
  }

  &-OtherAuth {
    width: calc(100% - 48px);
    margin: 12px 24px;
  }

  &-Spinner {
  }

  &-CaptchaImg {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &-Update {
      position: absolute;
      padding: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 1px;
      top: 1px;
    }
  }
}