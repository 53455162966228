@import '../../utils-old/global.scss';

.Login {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  &-Wrapper {
    /* max-width: 600px; */
    min-height: 260px;
    width: 100%;
    /* position: relative; */
    max-height: 100%;
    // overflow: auto;
    /* display: flex; */
    /* justify-content: center; */
    /* margin: 0 auto; */
  }
}

@media screen and (max-width: 600px) {
  .Login {
    .Banana {
      transform: scale(-1, 1);
      right: -120px;
    }

    &-Wrapper {
      left: 0;
      margin-left: 0;
    }
  }
}
