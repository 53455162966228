@import '../global.scss';

.MuiDialog-root .MuiDialog-paperScrollBody {
  overflow: unset;
}
// .MuiDialog-root.BackdropOff {

//   .MuiBackdrop-root {
//     display: none;
//   }

  
// }

// .MuiDialog-root.Right {

//   .MuiPaper-root {
//     float: right;
//   }

  
// }

// .MuiDialog-root.Pane {

//   // &[role="presentation"]:nth-of-type(n + 4) {
//   //   content: '';
//   // }

//   .MuiDialog-paper {
//     margin: 0px;
//     height: 100%;
//     display: flex;
//     flex-direction: column;
//     overflow: visible;

//     > div {
//       flex: 1;
//       display: flex;
//       flex-direction: column;
//     }

//     .Dialog-Content {
//       flex: 1;
//     }
//   }

//   .Dialog-Header,
//   .Dialog-Actions,
//   .MuiPaper-rounded {
//     border-radius: 0;
//   }

//   .MuiDialog-paperFullWidth {
//     margin-left: $LeftMenuWidth;
//     width: calc(100% - #{$LeftMenuWidth});
//   }

//   .MuiDialog-paperWidthFalse {
//     max-width: calc(100% - #{$LeftMenuWidth});
//   }

//   .MuiDialog-paperFullScreen.MuiDialog-paperScrollBody {
//     max-width: 100%;
//   }

//   .MuiDialog-scrollBody:after {
//     content: none;
//   }
  
//   .Dialog-Header .Dialog-Close {
//     position: absolute;
//     margin: 0;
//     top: 0;
//     left: -34px;
//     color: white;
//   }
// }