@import '../../../utils-old/global.scss';

.FormWrapper {
  margin: 0 auto;
  max-width: 600px;
  
  & > .TabsElement {
    position: relative;
    margin-bottom: -1px;
    
    .TabsElement-TabBtn {
      position: relative;
      // margin: 0px;
      padding: 8px 16px;
      border: 1px solid $BorderColor;
      border-bottom: 0px;
      @include BorderRadiusTop;
      background: #fff;
      margin-left: -5px;

      &:first-of-type {
        margin-left: 0px;
      }

      &_selected {
        background-color: $GrayBackground;
        z-index: 2;
        // color: var(--invariant-text-color);
      }
    }
  }
}