.Background {
  position: fixed;
  z-index: -1000;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-position: center top;
  background-size: cover;

  &_noRepeat {
    background-repeat: no-repeat;
  }

  &_fixed {
    position: fixed;
  }

  &_noAuth {
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &_notFound::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(6, 69, 133, 0.83);
  }
}
