@import '../../../../utils-old/global.scss';

.RegisterForm {

  position: relative;
  z-index: 1;
  background-color: $GrayBackground;
  box-sizing: border-box;
  border: 1px solid $BorderColor;

  .Input,
  .Checkbox,
  .Select,
  .CheckboxInput,
  .Login-Providers,
  .TickTimer,
  fieldset,
  & > .Login-SmsCodeButton,
  &-Actions {
    border-top: 1px solid $BorderColor;
  }

  .Select {
    padding: 0px;

    &-Caret {
      margin-left: 0px;
    }

    .Input {
      border-top: 0;
      margin: 0;
      width: 100%;
    }
  }

  .Input {

    &-HelpTooltip {
      right: 0;
    }
  }
  
  &-Text,
  &-Success {
    padding: 15px 15px;
    position: relative;
  }

  &-Actions {
    padding: 15px;
    display: flex;
    justify-content: space-between;
  }

  &-Agreement {
    font-size: 12px;
  }

  &-Submit {
    padding: 10px 4% 11px;
  }

  &-Success {
    text-align: center;
  }

  &-NewRequest {
    margin-top: 16px;
  }

  input::placeholder {
    color: #ababab;
  }

  fieldset {
    margin: 0;
    padding: 8px 24px 8.5px 41px;
    box-sizing: border-box;
    background-color: #fff;

    input {
      font-size: 14px;
      font-family: 'Open Sans', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    input::placeholder {
      color: #000;
    }
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }
}