:root {
  --danger-color: #cc0000;
  --danger-darken-color: #B30000;
  --border-color: #d3d9e0;
  --background-color: #ffffff;
  --background-highlight-color: #f7f7f7;
  --main-color: #0066cc;
  --main-darken-color: #004d99;
  --main-lighten-color: #65b2ff;
  --highlight-color: #89ca5e;
  --text-color: #070707;
  --invariant-text-color: #ffffff;
  --text-muted-color: #979797;
  --placeholder-color: #E3E3E3;
}